<template>
    <div>
        <div id="map"></div>

        <img class="image-hidden" src="https://icongr.am/material/rotate-right.svg?size=24&color=ffffff" />

        <div class="search">
            <input v-model="searchText" type="text" name="search" class="search-input" placeholder="Введите номера ТС" v-on:keyup.enter="onClickSearch" />
            <a class="button search-button outline is-vertical-align is-center" @click="onClickSearch">
                <img :class="loading ? 'rotate' : ''" :src="loading ? 'https://icongr.am/material/rotate-right.svg?size=24&color=ffffff' : 'https://icongr.am/material/magnify.svg?size=24&color=ffffff'" />
            </a>
        </div>

        <div id="wrapper">
            <div class="zoom-wrapper">
                <a class="button outline is-vertical-align is-center round zoom zoom1" @click="onClickZoomIn" v-bind:class="zoom_in_enable ? '' : 'disabled'">
                    <img src="https://icongr.am/material/plus.svg?size=22&color=555555" />
                </a>
                <a class="button outline is-vertical-align is-center round zoom zoom2" @click="onClickZoomOut" v-bind:class="zoom_out_enable ? '' : 'disabled'">
                    <img src="https://icongr.am/material/minus.svg?size=22&color=555555" />
                </a>
            </div>

            <div class="map-wrapper">
                <a class="button outline is-vertical-align is-center round map map1" @click="onClickNavi">
                    <img :src="'https://icongr.am/material/near-me.svg?size=26&color=' + navi_color" />
                </a>
                <a class="button outline is-vertical-align is-center round map map2" @click="onClickMapToggle">
                    <img :src="'https://icongr.am/material/' + (map_type == 0 ? 'earth' : 'map-outline') + '.svg?size=26&color=555555'" />
                </a>
            </div>

            <div v-if="menu_opened" id="main-card" class="card main">
                <a class="button outline is-vertical-align is-center close" @click="onClickMenu">
                    <img :src="'https://icongr.am/material/close.svg?size=26&color=555555'" />
                </a>

                <div class="col-12 menu">
                    <div class="row">
                        <div class="menu-header">Объект «{{ objectCurrent.name }}»</div>
                        <div class="menu-status">
                            <div class="status" :class="objectCurrent.online ? 'status-color-green' : 'status-color-red'"></div>
                            <span :style="{ color: objectCurrent.online ? '#4CAF50' : '#F44336' }">{{ objectCurrent.online ? 'в сети' : 'не в сети' }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="menu-subheader">обновлено {{ getSecondsStr(objectCurrent.actual) }} назад</div>
                        <!-- <div class="menu-subheader">обновлено {{getSecondsStr(objectCurrent.actual, true)}} назад</div> -->
                    </div>

                    <hr />

                    <div class="row">
                        <img class="param-icon" :src="'https://icongr.am/material/map-marker.svg?size=20&color=00B8D4'" /><span class="param-header">Адрес:</span> <span class="param-value">{{ address }}</span>
                    </div>
                    <div class="row">
                        <img class="param-icon" :src="'https://icongr.am/material/signal.svg?size=20&color=00C853'" /><span class="param-header">Связь GSM:</span> <span class="param-value">{{ 4 * 20 }}%</span>
                        <!-- <img class="param-icon" :src="'https://icongr.am/material/signal.svg?size=20&color=00C853'" /><span class="param-header">Связь GSM:</span> <span class="param-value">{{ objectCurrent.data.mqua * 20 }}%</span> -->
                    </div>
                    <div class="row">
                        <img class="param-icon" :src="'https://icongr.am/material/speedometer-slow.svg?size=20&color=FFA726'" /><span class="param-header">Скорость:</span> <span class="param-value">{{ objectCurrent.data.gspe }} км/ч</span>
                    </div>

                    <!-- <div class="row">
                        <img class="param-icon" :src="'https://icongr.am/material/satellite-variant.svg?size=20&color=7C4DFF'" /><span class="param-header">Местоположение:</span>
                        <span class="param-value">{{ objectCurrent.data.gsta || objectCurrent.data.gsat > 2 ? 'определено' : 'не определено' }}</span>
                    </div> -->
                    <!-- <div class="row"><img class="param-icon" :src="'https://icongr.am/material/flash.svg?size=20&color=FF5252'" /><span class="param-header">Питание:</span> <span class="param-value">в норме</span></div> -->
                </div>

                <div class="row">
                    <div class="col"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import config from '../config'

    import * as L from 'leaflet'
    import 'leaflet-polylinedecorator'
    import 'leaflet/dist/leaflet.css'

    import '../assets/js/Yandex.js'

    /* leaflet icon */
    delete L.Icon.Default.prototype._getIconUrl
    L.Icon.Default.mergeOptions({
        iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
        iconUrl: require('leaflet/dist/images/marker-icon.png'),
        shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    })

    // import moment from 'moment-timezone'

    // moment.locale('ru')
    // moment.tz.setDefault(config.timezoneString)

    export default {
        name: 'Position',
        components: {
        },
        data() {
            return {
                map: null,
                mynex: {
                    uid: 0,
                    series: '',
                    token: '',
                },
                object_marker: null,
                updateTimer: null,
                navi_color: '555555',
                navi_watch: false,
                zoom_in_enable: true,
                zoom_out_enable: true,
                zoom: 13,
                menu_opened: false,
                menu_header: '',
                menu_subheader: '',
                loading: false,
                searchText: '',
                markers: [],
                iconTruck: null,
                truckSelectedCurrent: -1,
                truckSelectedOld: -1,
                colorsHtml: [
                    '#F44336', // red
                    '#4CAF50', // green
                    '#2196F3', // blue
                    '#FF9800', // orange
                    '#9C27B0', // purple
                    '#3F51B5', // indigo
                    '#00BCD4', // cyan
                    '#FFEB3B', // yellow
                    '#795548', // brown
                    '#E91E63', // pink
                    '#673AB7', // deep-purple
                    '#03A9F4', // light-blue
                    '#009688', // teal
                    '#8BC34A', // light-green
                    '#FF5722', // deep-orange
                    '#607D8B', // blue-grey
                    '#666', // grey-1
                    '#777', // grey-2
                    '#888', // grey-3
                    '#999', // grey-4
                    '#aaa', // grey-5
                    '#bbb', // grey-6
                ],
                address: '',
                lat_old: 0,
                lng_old: 0,
                yandex_api_flag: false,
                mapnik: null,
                sat: null,
                map_type: +localStorage.getItem('map_type'),
            }
        },
        computed: {
            objectCurrent: function() {
                return this.markers[this.truckSelectedCurrent].options.object
            },
        },
        created() {
            this.iconLoad()
            // avtovostok
            this.apiMynexGet('auth/login', { name: '12140', pass: '180405' }, res => {
            // this.apiMynexGet('auth/login', { name: '10864', pass: '812394' }, res => {
                // this.apiMynexGet('auth/login', { name: 'demo', pass: 'demo123' }, res => {
                if (res.success) {
                    this.mynex.uid = res.uid
                    this.mynex.series = res.series
                    this.mynex.token = res.token
                    this.$notify({
                        duration: 10000,
                        text: 'Для поиска введите один или несколько номеров ТС и нажмите кнопку «Найти»',
                        type: 'primary',
                        close: true,
                    })
                } else {
                    this.$notify({
                        title: 'Ошибка',
                        duration: 10000,
                        text: 'Не удалось авторизоваться в системе. Попробуйте обновить страницу.',
                        type: 'error',
                    })
                }
            })
        },
        mounted() {
            this.mapCreate()
        },
        destroyed() {
            if (this.updateTimer) {
                clearTimeout(this.updateTimer)
                this.updateTimer = null
            }
        },
        methods: {
            elementShow(el) {
                if (el) {
                    el.style.display = 'inline'
                }
            },

            elementHide(el) {
                if (el) {
                    el.style.display = 'none'
                }
            },

            iconLoad() {
                this.makeRequest('GET', './img/truck.svg', false).then(res => {
                    if (res) {
                        this.iconTruck = res
                    }
                })
            },

            addressUpdate() {
                this.apiMynexGet('object/geo', { oid: this.objectCurrent.oid }, res => {
                    if (res.response.success) {
                        this.address = res.response.address
                    } else {
                        this.address = 'не найден'
                    }
                })
            },

            onClickSearch() {
                this.$notify({ clean: true })

                if (this.loading) {
                    return
                }

                this.loading = true

                if (this.markers.length) {
                    for (const marker of this.markers) {
                        marker.off('click')
                        this.map.removeLayer(marker)
                    }
                    this.markers = []
                }

                this.menu_opened = false
                this.navi_watch = false
                this.navi_color = '555555'

                this.truckSelectedOld = -1
                this.truckSelectedCurrent = -1

                this.apiMynexGet('object/list', {}, res => {
                    this.loading = false
                    if (res.response.success) {
                        this.objects = res.response.data

                        const icon = L.divIcon({
                            html: this.iconTruck,
                            iconSize: [30, 60],
                            iconAnchor: [15, 30],
                        })

                        let findFlag = false
                        let findList = ''
                        const bounds = []
                        let i = 0
                        for (let object of this.objects) {
                            const regexp = new RegExp(`(^|[ ;.,+-])${object.name}([ ;.,+-]|$)`, 'i')
                            if (this.searchText.search(regexp) != -1) {
                                findFlag = true
                                icon.options.className = 'marker-truck truck-' + i
                                const marker = new L.marker([object.data.glat, object.data.glng], {
                                    zIndexOffset: 2000,
                                    icon,
                                    index: i,
                                    object,
                                })
                                .bindTooltip('', {
                                    permanent: true,
                                    direction: 'right',
                                    offset: [30, 0],
                                    zIndexOffset: 1000,
                                    offset_orig: 1000,
                                })
                                .addTo(this.map)

                                this.updateTruckTooltip(marker)

                                this.setTruckColor(i, this.colorsHtml[object.index])

                                marker.on('click', this.onClickObject)

                                marker.setRotationAngle(object.data.gcou)

                                if (findList != '') {
                                    findList += ', '
                                }
                                findList += object.name

                                this.markers.push(marker)
                                bounds.push([object.data.glat, object.data.glng])
                                i++
                            }
                        }

                        if (findFlag) {
                            let text
                            if (this.markers.length > 1) {
                                text = 'найдены номера'
                                setTimeout(() => {
                                    this.map.flyToBounds(bounds, { maxZoom: 16, padding: [80, 80] })
                                }, 10)
                            } else {
                                text = 'найден номер'
                                const highlight = this.selectTruckElement(0, 'highlight')
                                if (highlight) {
                                    this.elementShow(highlight)
                                    this.truckSelectedOld = 0
                                    this.truckSelectedCurrent = 0
                                    this.navi_watch = true
                                    this.navi_color = '007fff'
                                    this.menu_opened = true
                                    this.viewTarget()
                                    this.lat_old = this.markers[0].options.object.data.glat
                                    this.lng_old = this.markers[0].options.object.data.glng
                                    this.address = '...'
                                    this.addressUpdate()
                                }
                            }
                            this.$notify({
                                title: 'Поиск транспорта',
                                duration: 5000,
                                text: `Успешно ${text}: ${findList}`,
                                type: 'success',
                            })
                        } else {
                            this.$notify({
                                title: 'Ошибка',
                                duration: 10000,
                                text: 'Не удалось найти ни одного ТС. Попробуйте изменить строку поиска.',
                                type: 'error',
                            })
                        }

                        if (this.updateTimer) {
                            clearTimeout(this.updateTimer)
                            this.updateTimer = null
                        }

                        this.updateTimer = setTimeout(() => {
                            this.mynexObjectsUpdate()
                        }, 5000)
                    } else {
                        this.$notify({
                            title: 'Ошибка',
                            duration: 10000,
                            text: 'Не удалось загрузить объекты. Попробуйте выполнить поиск снова или перезагрузите страницу.',
                            type: 'error',
                        })
                    }
                })
            },

            updateTruckTooltip(marker) {
                let cl = 'status-color-green'
                if (!marker.options.object.online) {
                    cl = 'status-color-red'
                }
                let name = `<div class="status ${cl}"></div><span class="label-name">${marker.options.object.name}</span>`
                marker.setTooltipContent(name)
                marker.update()
            },

            selectTruckElement(index, el) {
                let elem = document.querySelectorAll(`.truck-${index} svg path#${el}`)
                if (elem) {
                    return elem[0]
                } else {
                    return null
                }
            },

            setTruckColor(index, color) {
                let truckCab = this.selectTruckElement(index, 'cab')
                let truckBodyWork = this.selectTruckElement(index, 'bodywork')
                if (truckCab) {
                    truckCab.style.fill = color
                }
                if (truckBodyWork) {
                    truckBodyWork.style.fill = color
                }
            },

            onClickObject(obj) {
                this.deselectTruck()

                const index = obj.target.options.index

                const highlight = this.selectTruckElement(index, 'highlight')
                if (highlight) {
                    this.elementShow(highlight)
                    this.truckSelectedOld = index
                    this.truckSelectedCurrent = index
                    this.navi_watch = true
                    this.navi_color = '007fff'
                    this.menu_opened = true
                    this.viewTarget()
                    this.lat_old = this.markers[index].options.object.data.glat
                    this.lng_old = this.markers[index].options.object.data.glng
                    this.address = '...'
                    this.addressUpdate()
                }
            },

            deselectTruck() {
                if (this.truckSelectedCurrent >= 0) {
                    this.elementHide(this.selectTruckElement(this.truckSelectedCurrent, 'highlight'))
                    this.truckSelectedCurrent = -1
                }
            },

            onClickMenu() {
                if (this.menu_opened) {
                    this.menu_opened = false
                } else {
                    this.menu_opened = true
                    if (this.object_marker) {
                        this.navi_watch = true
                        this.navi_color = '007fff'
                    }
                }
                this.viewTarget()
            },

            onClickZoomIn() {
                if (this.zoom < 19) {
                    this.zoom++
                    if (this.navi_watch) {
                        this.viewTarget(this.zoom)
                    } else {
                        this.map.setZoom(this.zoom)
                    }
                    this.zoom_out_enable = true
                }
            },

            onClickZoomOut() {
                if (this.zoom > 8) {
                    this.zoom--
                    if (this.navi_watch) {
                        this.viewTarget(this.zoom)
                    } else {
                        this.map.setZoom(this.zoom)
                    }
                    this.zoom_in_enable = true
                }
            },

            viewTarget(z) {
                if (this.map) {
                    let marker = null
                    if (this.truckSelectedCurrent != -1) {
                        marker = this.markers[this.truckSelectedCurrent]
                    }
                    let pos = []
                    if (this.navi_watch && marker) {
                        pos = [marker.getLatLng().lat, marker.getLatLng().lng]
                    }

                    if (pos.length) {
                        setTimeout(() => {
                            if (z) {
                                this.zoom = z
                                if (marker) {
                                    this.map.setZoomAround(pos, this.zoom)
                                } else {
                                    this.map.setZoom(this.zoom)
                                }
                            } else {
                                let height
                                if (this.menu_opened) {
                                    const height_px = window.getComputedStyle(document.getElementById('main-card')).height
                                    height = -height_px.substr(0, height_px.length - 2) / 2
                                } else {
                                    height = 0
                                }
                                this.map.panToOffset(pos, [0, height])
                            }
                        }, 10)
                    }
                }
            },

            onClickNavi() {
                if (this.truckSelectedCurrent == -1 && this.truckSelectedOld != -1) {
                    this.truckSelectedCurrent = this.truckSelectedOld
                } else if (this.markers.length && this.truckSelectedOld != -1) {
                    this.truckSelectedCurrent = this.truckSelectedOld
                } else if (this.markers.length) {
                    this.truckSelectedCurrent = 0
                }

                if (this.truckSelectedCurrent != -1) {
                    if (this.navi_watch) {
                        this.navi_watch = false
                        this.navi_color = '555555'
                    } else {
                        this.navi_watch = true
                        this.navi_color = '007fff'
                        this.viewTarget()
                    }
                }
            },

            makeRequest(method, url, jsonFlag = true) {
                return new Promise(function(resolve, reject) {
                    let xhr = new XMLHttpRequest()
                    xhr.open(method, url)
                    xhr.onload = function() {
                        if (this.status >= 200 && this.status < 300) {
                            let data
                            if (jsonFlag) {
                                data = JSON.parse(xhr.responseText)
                            } else {
                                data = xhr.responseText
                            }
                            resolve(data)
                        } else {
                            reject({
                                status: this.status,
                                statusText: xhr.statusText,
                            })
                        }
                    }
                    xhr.onerror = function() {
                        reject({
                            status: this.status,
                            statusText: xhr.statusText,
                        })
                    }
                    xhr.send()
                })
            },

            apiMynexGet(path, params, callback) {
                if (!params) {
                    params = {}
                }

                params.uid = this.mynex.uid
                params.series = this.mynex.series
                params.token = this.mynex.token

                let params_str = Object.entries(params)
                    .map(([key, val]) => `${key}=${val}`)
                    .join('&')
                this.makeRequest('GET', `${config.urlApiMynex}/${path}?${params_str}`).then(res => {
                    if (callback) {
                        callback(res)
                    }
                })
            },

            layer_mapSelect() {
                if (this.map) {
                    this.map.removeLayer(this.sat)
                    this.map.addLayer(this.mapnik)
                    this.map_change_window_flag = true
                    this.map_type = 0
                    localStorage.setItem('map_type', this.map_type)
                }
            },

            layer_satSelect() {
                if (this.map) {
                    this.map.removeLayer(this.mapnik)
                    if (!this.yandex_api_flag) {
                        const this_ = this
                        this.loadYandexApi(function() {
                            this_.yandex_api_flag = true
                            this_.map.addLayer(this_.sat)
                            if (this_.sat._yandex) {
                                this_.sat._yandex.container.fitToViewport()
                            }
                        })
                    } else {
                        this.map.addLayer(this.sat)
                        if (this.sat._yandex) {
                            this.sat._yandex.container.fitToViewport()
                        }
                    }
                    this.map_type = 1
                    localStorage.setItem('map_type', this.map_type)
                }
            },

            loadYandexApi(callback) {
                var head = document.getElementsByTagName('head')[0]
                var script = document.createElement('script')
                script.type = 'text/javascript'
                script.src = 'https://api-maps.yandex.ru/2.1/?lang=ru_RU&apikey=1cb8afbb-8b3c-4b07-92d6-c434e095b08b'

                // then bind the event to the callback function
                // there are several events for cross browser compatibility
                script.onreadystatechange = callback
                script.onload = callback

                // fire the loading
                head.appendChild(script)
            },

            onClickMapToggle() {
                if (this.map_type == 0) {
                    this.layer_satSelect()
                } else if (this.map_type == 1) {
                    this.layer_mapSelect()
                }
            },

            mapCreate() {
                L.Map.prototype.panToOffset = function(latlng, offset, options) {
                    let x = this.latLngToContainerPoint(latlng).x - offset[0]
                    let y = this.latLngToContainerPoint(latlng).y - offset[1]
                    let point = this.containerPointToLatLng([x, y])
                    return this.setView(point, this._zoom, { pan: options })
                }
                this.map = new L.map('map', {
                    center: [43.121284, 131.901917],
                    zoom: this.zoom,
                    zoomControl: false,
                    doubleClickZoom: false,
                    attributionControl: false,
                })

                // Схема
                this.mapnik = L.tileLayer('https://map.mynex.ru/tile/{z}/{x}/{y}.png', {
                    maxZoom: 20,
                    minZoom: 8,
                    retina: '@2x',
                    detectRetina: true,
                })

                // Спутник
                this.sat = L.yandex('satellite').on('load', function() {
                    let ymaps_base_class = '.' + this._container.firstChild.className.replace('-map', '')
                    document.querySelectorAll(ymaps_base_class + '-copyright__fog')[0].style.display = 'none'
                    document.querySelectorAll(ymaps_base_class + '-copyright__text')[0].style.display = 'none'
                    document.querySelectorAll(ymaps_base_class + '-copyright')[0].style.float = 'right'
                    // document.querySelectorAll(ymaps_base_class + '-copyright')[0].style.top = '-38px'
                    document.querySelectorAll(ymaps_base_class + '-copyright__content')[0].style.borderRadius = '2px'
                })

                if (this.map_type == 0) {
                    this.layer_mapSelect()
                } else if (this.map_type == 1) {
                    this.layer_satSelect()
                }

                let obj = this
                this.map.on('click', () => {
                    this.deselectTruck()
                    this.menu_opened = false
                    this.navi_watch = false
                    this.navi_color = '555555'
                })
                this.map.on('dragstart', () => {
                    obj.navi_watch = false
                    obj.navi_color = '555555'
                })

                this.map.on('zoomend', () => {
                    let pos
                    if (obj.navi_watch && this.object_marker) {
                        pos = [this.object_marker.getLatLng().lat, this.object_marker.getLatLng().lng]
                    }

                    let height
                    if (obj.menu_opened && obj.link.order) {
                        const height_px = window.getComputedStyle(document.getElementById('main-card')).height
                        height = -height_px.substr(0, height_px.length - 2) / 2
                    } else {
                        height = 0
                    }
                    if (pos) {
                        obj.map.panToOffset(pos, [0, height])
                    }
                    obj.zoom = obj.map.getZoom()
                    obj.zoom_in_enable = obj.zoom < 19
                    obj.zoom_out_enable = obj.zoom > 8
                })
            },

            mynexObjectsUpdate() {
                this.apiMynexGet('object/list', {}, res => {
                    if (res.response.success) {
                        let obj = null
                        this.objects = res.response.data
                        for (let object of this.objects) {
                            for (const marker of this.markers) {
                                if (marker.options.object.oid == object.oid) {
                                    marker.setLatLng([object.data.glat, object.data.glng])
                                    marker.setRotationAngle(object.data.gcou)
                                    marker.options.object = object
                                    this.updateTruckTooltip(marker)
                                    if (marker.options.index == this.truckSelectedCurrent) {
                                        obj = object
                                    }
                                }
                            }
                        }
                        this.viewTarget()
                        if (obj && (obj.data.glat != this.lat_old || obj.data.glng != this.lng_old)) {
                            this.addressUpdate()
                            this.lat_old = obj.data.glat
                            this.lng_old = obj.data.glng
                        }
                    }
                })
                if (this.updateTimer) {
                    this.updateTimer = setTimeout(() => {
                        this.mynexObjectsUpdate()
                    }, 5000)
                }
            },

            getSecondsStr(seconds, hide_sec = false) {
                let time = ''
                let years = 0
                let days = 0
                let hours = 0
                if (seconds >= 60) {
                    if (seconds >= 3600) {
                        if (seconds >= 86400) {
                            if (seconds >= 31536000) {
                                let years = parseInt(seconds / 31536000)
                                seconds -= years * 31536000
                                let l = years.toString().slice(-1)
                                if (l == 1) {
                                    time += years + ' год '
                                } else if (l > 0 && l < 5) {
                                    time += years + ' года '
                                } else {
                                    time += years + ' лет '
                                }
                            }
                            days = parseInt(seconds / 86400)
                            seconds -= days * 86400
                            let l = days.toString().slice(-1)
                            if (days == 0) time += days + ' д. '
                            else if (days >= 11 && days <= 14) {
                                time += days + ' дней '
                            } else if (l == 1) {
                                time += days + ' день '
                            } else if (l > 0 && l < 5) {
                                time += days + ' дня '
                            } else {
                                time += days + ' дней '
                            }
                        }
                        hours = parseInt(seconds / 3600)
                        seconds -= hours * 3600
                        if (hours > 0 && years == 0 && days < 3) time += hours + ' ч. '
                    }
                    let mins = parseInt(seconds / 60)
                    seconds -= mins * 60
                    if (years == 0 && days == 0 && mins > 0 && hours < 12) time += mins + ' мин. '
                    if (!hide_sec) {
                        if (days == 0 && hours == 0 && mins < 30) {
                            let s = parseInt(seconds)
                            if (s < 0) s = 0
                            if (s) {
                                time += s + ' сек.'
                            }
                        }
                    }
                } else {
                    if (hide_sec) time = 'менее минуты'
                    else {
                        let s = parseInt(seconds)
                        if (s < 0) s = 0
                        time += s + ' сек.'
                    }
                }
                return time
            },
        },
    }
</script>

<style>
    .avatar-icon {
        width: 46px;
        height: 46px;
        top: -43px;
        left: -18px;
        position: absolute;
        border-radius: 50%;
    }
    .avatar-disabled {
        filter: brightness(300%);
        filter: sepia(100%);
        filter: gray;
        filter: blur(0.5em);
    }
    .avatar-shadow {
        width: 50px !important;
        height: 50px !important;
        top: -42px;
        left: -20px;
        position: absolute;
    }
    .avatar-status {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        top: -55px;
        right: -25px;
        position: absolute;
        border: solid 1px white;
        box-shadow: 0 0 1px black;
    }
    .avatar-guest {
        /* создан из цвета #00a4d6 на странице https://codepen.io/sosuke/pen/Pjoqqp */
        filter: invert(62%) sepia(60%) saturate(3483%) hue-rotate(155deg) brightness(86%) contrast(101%);
    }

    .status {
        /* border-radius: 50%; */
        width: 8px;
        height: 8px;
        display: inline-block;
        margin-right: 8px;
        margin-bottom: 1px;
        box-shadow: 1px 1px 1px gray;
    }

    .status-color-red {
        background-color: #f44336;
    }

    .status-color-green {
        background-color: #4caf50;
    }

    .status-color-grey {
        background-color: grey;
    }
</style>

<style scoped>
    #wrapper {
        width: 100%;
        position: absolute;
        z-index: 1000;
        display: flex;
        align-items: center;
        flex-direction: column-reverse;
        pointer-events: inherit;
    }
    .main {
        bottom: -3px;
        width: 90%;
        position: fixed;
        max-width: 600px;
        padding-left: 14px;
        padding-right: 14px;
    }
    .text-h1 {
        font-weight: 300;
        font-size: 6rem !important;
        line-height: 6rem;
        letter-spacing: -0.015625em !important;
    }
    .text-h2 {
        font-weight: 300;
        font-size: 3.75rem !important;
        line-height: 3.75rem;
        letter-spacing: -0.0083333333em !important;
    }
    .text-h3 {
        font-weight: 400;
        font-size: 3rem !important;
        line-height: 3.125rem;
        letter-spacing: normal !important;
    }
    .text-h4 {
        font-weight: 400;
        font-size: 2.125rem !important;
        line-height: 2.5rem;
        letter-spacing: 0.0073529412em !important;
    }
    .text-h5 {
        font-weight: 400;
        font-size: 1.5rem !important;
        line-height: 2rem;
        letter-spacing: normal !important;
    }
    .text-h6 {
        font-weight: 500;
        font-size: 1.25rem !important;
        line-height: 2rem;
        letter-spacing: 0.0125em !important;
    }
    .text-subtitle-1 {
        font-size: 1.1rem !important;
        font-weight: 400;
        line-height: 1.75rem;
        letter-spacing: 0.009375em !important;
    }
    .text-subtitle-2 {
        font-size: 0.875rem !important;
        font-weight: 500;
        line-height: 1.375rem;
        letter-spacing: 0.0071428571em !important;
    }
    .text-body-1 {
        font-size: 1rem !important;
        font-weight: 400;
        line-height: 1.5rem;
        letter-spacing: 0.03125em !important;
    }
    .text-body-2 {
        font-size: 0.875rem !important;
        font-weight: 400;
        line-height: 1.25rem;
        letter-spacing: 0.0178571429em !important;
    }
    .text-button {
        font-size: 0.875rem !important;
        font-weight: 500;
        line-height: 2.25rem;
        letter-spacing: 0.0892857143em !important;
        text-transform: uppercase !important;
    }
    .text-caption {
        font-size: 0.75rem !important;
        font-weight: 400;
        line-height: 1.25rem;
        letter-spacing: 0.0333333333em !important;
    }
    .text-overline {
        font-size: 0.75rem !important;
        font-weight: 500;
        line-height: 2rem;
        letter-spacing: 0.1666666667em !important;
        text-transform: uppercase !important;
    }
    hr {
        border: none;
        background-color: #eee;
        height: 1px;
        margin: 1rem 0;
    }
    .complete {
        border-color: #007fff !important;
    }
    .current {
        background-color: #c5e2ff !important;
    }
    .header-wrapper {
        top: 10px;
        left: 18px;
        position: absolute;
        z-index: 1;
        pointer-events: none;
    }
    .button.menu {
        position: relative;
        width: 44px !important;
        height: 44px !important;
        margin-left: 0 !important;
        border: none;
        pointer-events: all;
        border-color: #fff !important;
        background-color: #fff !important;
        box-shadow: 0px 1px 2px #999;
    }
    .menu-text {
        margin: 0 15px;
        color: #444444;
        font-weight: 500;
        font-size: 2rem !important;
        line-height: 2.5rem;
        letter-spacing: 0.0073529412em !important;
    }
    .zoom-wrapper {
        top: calc(40px + 15vh);
        left: 0px;
        position: fixed;
        z-index: 1;
    }
    .zoom {
        position: absolute;
        width: 40px !important;
        height: 40px !important;
        left: 10px;
        border-radius: 2px !important;
        border-color: #fff !important;
        background-color: #fff !important;
        box-shadow: 0px 1px 2px #999;
        margin-left: 0 !important;
    }
    .zoom1 {
        top: 0px;
    }
    .zoom2 {
        top: 42px;
    }
    .map-wrapper {
        top: calc(24px + 15vh);
        /* top: 15%; */
        right: 0px;
        position: fixed;
        z-index: 1;
    }
    .map {
        position: absolute;
        width: 50px !important;
        height: 50px !important;
        right: 10px;
        border-color: #fff !important;
        background-color: #fff !important;
        box-shadow: 0px 1px 2px #999;
    }
    .map1 {
        top: 0px;
    }
    .map2 {
        top: 70px;
    }
    .close {
        position: absolute;
        right: 14px;
        top: 6px;
        width: 40px;
        height: 40px;
        border: none;
    }
    .button {
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently
                                      supported by Chrome, Edge, Opera and Firefox */
    }
    .button.disabled {
        opacity: 0.5 !important;
        cursor: default;
        transform: none !important;
    }
    .button.call {
        margin: 0;
        color: #555;
        font-weight: 600;
        font-size: 1.2rem !important;
        line-height: 1.2rem;
        letter-spacing: 0.0073529412em !important;
        min-width: 140px;
        padding: 7px 15px !important;
    }
    .button.call img {
        margin-right: 10px;
        margin-left: 4px;
    }
    .button img {
        max-width: initial !important;
    }
    .button,
    [type='button'],
    [type='reset'],
    [type='submit'],
    button {
        padding: 1rem !important;
        opacity: 1 !important;
    }
    button {
        font-family: initial;
    }
    .button.round {
        border-radius: 50%;
        /* border-radius: 50%; */
        width: 60px;
        height: 60px;
        margin-bottom: 10px;
    }
    .button:active,
    [type='button']:active,
    [type='reset']:active,
    [type='submit']:active,
    button:active {
        -webkit-transform: scale(0.97);
        transform: scale(0.97);
    }
    input:disabled,
    button:disabled,
    input:disabled:hover,
    button:disabled:hover {
        opacity: 0.85 !important;
        cursor: default !important;
        -webkit-transform: initial !important;
        transform: initial !important;
    }
    #map {
        height: 100%;
        width: 100%;
        position: absolute;
    }
    .leaflet-tooltip {
        border: none !important;
        background-color: inherit !important;
        box-shadow: none !important;

        font: 14px Arial, Verdana, Helvetica, sans-serif;
        font-weight: bold !important;
        text-shadow: 1px 1px 1px white, -1px -1px 1px white;
    }
    .leaflet-tooltip:before {
        display: none !important;
    }
    .leaflet-container {
        background-color: #fff !important;
    }
    .leaflet-tile-pane {
        opacity: 0.8;
    }
    .leaflet-control-container {
        position: absolute;
        top: 18%;
    }
    .leaflet-square-off {
        /* любое свойство достаточно для переопределения квадратика className: 'leaflet-square-off' */
        display: block;
    }
    .leaflet-touch .leaflet-bar a {
        width: 40px !important;
        height: 40px !important;
        line-height: 40px !important;
    }
    .text-warning {
        color: #1565c0;
    }
    .text-nowrap {
        display: flex;
        flex-wrap: nowrap !important;
        align-items: center !important;
    }
    .delivery-info {
        display: flex;
        justify-content: center;
        margin: 0;
        padding: 0 10px;
        align-items: center;
    }
    .delivery-info-text {
        padding-top: 4px;
        padding-left: 5px;
        font-size: 12px;
        line-height: 14px;
        text-align: left;
        margin-left: 6px !important;
    }
    .status-image {
        margin-right: 5px;
        top: 5px;
        position: relative;
    }
    input[type='text'] {
        border: 1px solid #fff !important;
        border-radius: 3px;
        background: #ffffff !important;
        outline: none;
        color: #555;
    }
    input[type='text']:hover {
        border: 1px solid #007fff !important;
    }
    input[type='text']:focus {
        border: 1px solid #007fff !important;
    }
    .search-input {
        font-size: 20px !important;
        height: 40px !important;
        line-height: 40px !important;
        padding-left: 10px !important;

        box-shadow: 0px 1px 2px #999;
    }
    .search {
        top: 12px;
        right: 10px;
        margin-left: 10px;
        position: absolute;
        display: flex;
        z-index: 20000;
    }
    .search-button {
        border: 0;
        border-radius: 3px !important;
        background: #007fff !important;
        margin-left: 10px !important;
        margin-top: 1px;
        width: 70px;
        height: 38px;
        box-shadow: 0px 1px 2px #999;
    }
    .rotate {
        animation: rotation 1s infinite linear;
    }
    @keyframes rotation {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(359deg);
        }
    }
    .marker-truck {
        width: 60px;
        height: 30px;
    }
    .image-hidden {
        opacity: 0;
        z-index: -1;
        position: absolute;
    }
    .param-icon {
        margin-right: 6px;
        bottom: 1px;
        position: relative;
    }
    .param-header {
        color: #999;
    }
    .param-value {
        margin-left: 8px;
        font-weight: 500;
        color: #555;
    }
    .menu-status {
        color: #555;
        line-height: 32px;
        margin-left: 12px;
        /* text-shadow: 1px 1px 1px #999; */
    }
    .menu-header {
        color: #555;
        font-weight: 500;
        font-size: 20px;
    }
    .menu-subheader {
        color: #999;
        line-height: 14px;
        font-size: 14px;
    }
    .menu {
        margin: 0 20px;
    }
</style>
