let env = process.env.NODE_ENV || 'development'

let config = module.exports = {};


// order
config.order = {}
// order status
config.order.status = {}
config.order.status.new = 0
config.order.status.way_point = 1
config.order.status.way_guest = 2
config.order.status.complete = 3
config.order.status.forming = 4
config.order.status.passed = 5
config.order.status.deleted = 6

// link
config.link = {}
config.link.type = {}
config.link.type.review = 0
config.link.type.position = 1

// review
config.review = {}
// review status
config.review.status = {}
config.review.status.new = 0
config.review.status.opened = 1
config.review.status.voted = 2
config.review.status.closed = 3
config.review.status.responded = 4
config.review.status.deleted = 5


// position
config.position = {}
// position status
config.position.status = {}
config.position.status.new = 0
config.position.status.opened = 1
config.position.status.closed = 2
config.position.status.deaded = 3
config.position.status.deleted = 4


// notify
config.notify = {}
// notify status
config.notify.status = {}
config.notify.status.new = 0
config.notify.status.success = 1
config.notify.status.error = 2
config.notify.status.access_denied = 3
config.notify.status.deleted = 4


if (env == 'development') {
    config.urlApiDarall = 'http://localhost:5500'
    // config.urlApiDarall = 'https://api.darall.pro/v1'
} else {
    config.urlApiDarall = 'https://api.darall.pro/v1'
}

config.urlApiMynex = 'https://api.mynex.ru/v1'
