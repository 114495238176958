<template>
    <div id="app">
        <Position />
        <notifications position="bottom center" />
    </div>
</template>

<script>
    import Position from './components/Position.vue'

    export default {
        name: 'App',
        components: {
            Position,
        },
        data() {
            return {
            }
        }
    }
</script>

<style>
    /* roboto-regular - cyrillic */
    @font-face {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      src: url('assets/fonts/roboto-v27-cyrillic-regular.eot'); /* IE9 Compat Modes */
      src: local(''),
           url('assets/fonts/roboto-v27-cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
           url('assets/fonts/roboto-v27-cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
           url('assets/fonts/roboto-v27-cyrillic-regular.woff') format('woff'), /* Modern Browsers */
           url('assets/fonts/roboto-v27-cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
           url('assets/fonts/roboto-v27-cyrillic-regular.svg#Roboto') format('svg'); /* Legacy iOS */
    }

    /* roboto-500 - cyrillic */
    @font-face {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      src: url('assets/fonts/roboto-v27-cyrillic-500.eot'); /* IE9 Compat Modes */
      src: local(''),
           url('assets/fonts/roboto-v27-cyrillic-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
           url('assets/fonts/roboto-v27-cyrillic-500.woff2') format('woff2'), /* Super Modern Browsers */
           url('assets/fonts/roboto-v27-cyrillic-500.woff') format('woff'), /* Modern Browsers */
           url('assets/fonts/roboto-v27-cyrillic-500.ttf') format('truetype'), /* Safari, Android, iOS */
           url('assets/fonts/roboto-v27-cyrillic-500.svg#Roboto') format('svg'); /* Legacy iOS */
    }

    html,
    body {
        height: 100%;
        width: 100%;
        padding: 0;
        margin: 0;
    }

    #app {
        font-family: 'Roboto', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
</style>
